export interface Communicator {
  id: string;
  name: string;
  description: string;
  author: string;
  email: string;
  rootBoard: string;
  boards: string[];
}


export const defaultCommunicators: Communicator[] = [
  {
    id: "cboard_default",
    name: "Komunicare's Communicator",
    description: "Komunicare's default communicator",
    author: "Komunicare Team",
    email: "info@komuni.care",
    rootBoard: "komunicare",
    boards: ["komunicare"]
  }
];
