import {Board} from "./types";


const komunicare: Board[] = [
  {
    isPublic: true,
    tiles: [
      {
        labelKey: "symbol.komunicare.feelings",
        image: "/symbols/komunicare/portada_emociones.svg",
        loadBoard: "feelingsBoard",
        sound: "",
        type: "folder",
        backgroundColor: "#2196F3",
        linkedBoard: false,
        id: "feelings"
      },
      {
        labelKey: "symbol.komunicare.chatTitle",
        image: "/symbols/komunicare/portada_frases.svg",
        loadBoard: "chatBoard",
        sound: "",
        type: "folder",
        backgroundColor: "#2196F3",
        linkedBoard: false,
        id: "chat"
      },
      {
        labelKey: "symbol.komunicare.food",
        image: "/symbols/komunicare/portada_comidas.svg",
        loadBoard: "foodBoard",
        sound: "",
        type: "folder",
        backgroundColor: "#2196F3",
        linkedBoard: false,
        id: "food"
      },
      {
        label: "Bebidas",
        labelKey: "symbol.komunicare.drinks",
        image: "/symbols/komunicare/portada_bebidas.svg",
        loadBoard: "drinksBoard",
        sound: "",
        type: "folder",
        backgroundColor: "#2196F3",
        linkedBoard: false,
        id: "drinks"
      },
      {
        labelKey: "symbol.komunicare.family",
        image: "/symbols/komunicare/portada_familia.svg",
        loadBoard: "familyBoard",
        sound: "",
        type: "folder",
        backgroundColor: "#2196F3",
        linkedBoard: false,
        id: "family"
      }
    ],
    locale: "es-ES",
    format: "cboard",
    description: "komunicareDescription",
    isFixed: true,
    name: "Tablero Komunicare",
    author: "Komunicare",
    email: "info@komuni.care",
    caption: "../logo.svg",
    grid: {
      rows: 3,
      columns: 6,
      order: [
        ["feelings", "chat", null, null, null, null],
        [null, null, null, null, null, null]
      ]
    },
    lastEdited: "2024-06-12T17:09:50-03:00",
    id: "komunicare",
    hidden: false
  },
  {
    isPublic: false,
    tiles: [
      {
        label: "asustado",
        labelKey: "symbol.komunicare.feelings.scared",
        image: "/symbols/komunicare/asustado.svg",
        id: "scared",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "cansado",
        labelKey: "symbol.komunicare.feelings.tired",
        image: "/symbols/komunicare/cansado.svg",
        id: "tired",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "contento",
        labelKey: "symbol.komunicare.feelings.happy",
        image: "/symbols/komunicare/contento.svg",
        id: "happy",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "enfermo",
        labelKey: "symbol.komunicare.feelings.sick",
        image: "/symbols/komunicare/enfermo.svg",
        id: "sick",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "enojado",
        labelKey: "symbol.komunicare.feelings.angry",
        image: "/symbols/komunicare/enojado.svg",
        id: "angry",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "triste",
        labelKey: "symbol.komunicare.feelings.sad",
        image: "/symbols/komunicare/triste.svg",
        id: "sad",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "vos",
        labelKey: "symbol.komunicare.feelings.you",
        image: "/symbols/komunicare/vos_nina.svg",
        id: "you_f",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "vos",
        labelKey: "symbol.komunicare.feelings.you",
        image: "/symbols/komunicare/vos_nino.svg",
        id: "you_m",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "yo",
        labelKey: "symbol.komunicare.feelings.me",
        image: "/symbols/komunicare/yo_nino.svg",
        id: "me_m",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "yo",
        labelKey: "symbol.komunicare.feelings.me",
        image: "/symbols/komunicare/yo_nina.svg",
        id: "me_f",
        backgroundColor: "rgb(187, 222, 251)"
      }
    ],
    id: "feelingsBoard",
    locale: "es-ES",
    format: "cboard",
    description: "",
    isFixed: true,
    nameKey: "symbol.komunicare.feelings",
    author: "Komunicare",
    email: "info@komuni.care",
    caption: "/symbols/komunicare/portada_emociones.svg",
    grid: {
      rows: 3,
      columns: 6,
      order: [
        ["scared", "tired", "happy", "sick", "angry", "sad"],
        ["you", "me", null, null, null, null]
      ]
    }
  },
  {
    isPublic: false,
    tiles: [
      {
        label: "me duele",
        labelKey: "symbol.komunicare.chat.hurt",
        image: "/symbols/komunicare/me_duele.svg",
        id: "hurt",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "me quiero dar vuelta",
        labelKey: "symbol.komunicare.chat.turn",
        image: "/symbols/komunicare/me_quiero_dar_vuelta.svg",
        id: "turn",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "me quiero sentar",
        labelKey: "symbol.komunicare.chat.sit",
        image: "/symbols/komunicare/me_quiero_sentar.svg",
        id: "sit",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "quiero ir al baño",
        labelKey: "symbol.komunicare.chat.bathroom",
        image: "/symbols/komunicare/quiero_ir_al_bano.svg",
        id: "bathroom",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "tengo sed",
        labelKey: "symbol.komunicare.chat.thirsty",
        image: "/symbols/komunicare/tengo_sed.svg",
        id: "thirsty",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "tengo hambre",
        labelKey: "symbol.komunicare.chat.hungry",
        image: "/symbols/komunicare/tengo_hambre.svg",
        id: "hungry",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "me pica la espalda",
        labelKey: "symbol.komunicare.chat.itches",
        image: "/symbols/komunicare/me_pica_la_espalda.svg",
        id: "itches",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "me siento mal, ¿Podés venír?",
        labelKey: "symbol.komunicare.chat.feelingBad",
        image: "/symbols/komunicare/me_siento_mal_podes_venir.svg",
        id: "feelingBad",
        backgroundColor: "rgb(187, 222, 251)"
      }
    ],
    id: "chatBoard",
    locale: "es-ES",
    format: "cboard",
    description: "",
    isFixed: true,
    nameKey: "symbol.komunicare.chatTitle",
    author: "Komunicare",
    email: "info@komuni.care",
    caption: "/symbols/komunicare/portada_frases.svg",
    grid: {
      rows: 3,
      columns: 6,
      order: [
        ["hurt", "turn", "sit", "bathroom", "thirsty", "hungry"],
        ["itches", "feelingBad", null, null, null, null]
      ]
    }
  },
  {
    isPublic: false,
    tiles: [
      {
        label: "Desayunos y Meriendas",
        labelKey: "symbol.komunicare.food.breakfasts",
        image: "/symbols/komunicare/portada_desayunos_meriendas.svg",
        loadBoard: "breakfastsBoard",
        sound: "",
        type: "folder",
        backgroundColor: "#2196F3",
        linkedBoard: false,
        id: "breakfasts"
      },
      {
        label: "Frutas y Verduras",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables",
        image: "/symbols/komunicare/portada_frutas_verduras.svg",
        loadBoard: "fruitsAndVegetablesBoard",
        sound: "",
        type: "folder",
        backgroundColor: "#2196F3",
        linkedBoard: false,
        id: "fruitsAndVegetables"
      },
      {
        label: "arroz",
        labelKey: "symbol.komunicare.food.rice",
        image: "/symbols/komunicare/arroz.svg",
        id: "rice",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "carne",
        labelKey: "symbol.komunicare.food.meat",
        image: "/symbols/komunicare/carne.svg",
        id: "meat",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "fideos",
        labelKey: "symbol.komunicare.food.noodles",
        image: "/symbols/komunicare/fideos.svg",
        id: "noodles",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "hamburguesa",
        labelKey: "symbol.komunicare.food.burger",
        image: "/symbols/komunicare/hamburguesa.svg",
        id: "burger",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "huevo cocido",
        labelKey: "symbol.komunicare.food.boiledEgg",
        image: "/symbols/komunicare/huevo_cocido.svg",
        id: "boiledEgg",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "huevo frito",
        labelKey: "symbol.komunicare.food.friedEgg",
        image: "/symbols/komunicare/huevo_frito.svg",
        id: "friedEgg",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "ketchup",
        labelKey: "symbol.komunicare.food.ketchup",
        image: "/symbols/komunicare/ketchup.svg",
        id: "ketchup",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "mayonesa",
        labelKey: "symbol.komunicare.food.mayonnaise",
        image: "/symbols/komunicare/mayonesa.svg",
        id: "mayonnaise",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "milanesa",
        labelKey: "symbol.komunicare.food.milanesa",
        image: "/symbols/komunicare/milanesa.svg",
        id: "milanesa",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pan",
        labelKey: "symbol.komunicare.food.bread",
        image: "/symbols/komunicare/pan.svg",
        id: "bread",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pancho",
        labelKey: "symbol.komunicare.food.hotdog",
        image: "/symbols/komunicare/pancho.svg",
        id: "hotdog",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "panqueque",
        labelKey: "symbol.komunicare.food.pancake",
        image: "/symbols/komunicare/panqueque.svg",
        id: "pancake",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "papas fritas",
        labelKey: "symbol.komunicare.food.fries",
        image: "/symbols/komunicare/papas_fritas.svg",
        id: "fries",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pescado",
        labelKey: "symbol.komunicare.food.fish",
        image: "/symbols/komunicare/pescado.svg",
        id: "fish",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pizza",
        labelKey: "symbol.komunicare.food.pizza",
        image: "/symbols/komunicare/pizza.svg",
        id: "pizza",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pollo",
        labelKey: "symbol.komunicare.food.chicken",
        image: "/symbols/komunicare/pollo.svg",
        id: "chicken",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pure",
        labelKey: "symbol.komunicare.food.puree",
        image: "/symbols/komunicare/pure.svg",
        id: "puree",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "queso",
        labelKey: "symbol.komunicare.food.cheese",
        image: "/symbols/komunicare/queso.svg",
        id: "cheese",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "sal",
        labelKey: "symbol.komunicare.food.salt",
        image: "/symbols/komunicare/sal.svg",
        id: "salt",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "salsa golf",
        labelKey: "symbol.komunicare.food.golfSauce",
        image: "/symbols/komunicare/salsa_golf.svg",
        id: "golfSauce",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "sandwich",
        labelKey: "symbol.komunicare.food.sandwich",
        image: "/symbols/komunicare/sandwich.svg",
        id: "sandwich",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "sopa",
        labelKey: "symbol.komunicare.food.soup",
        image: "/symbols/komunicare/sopa.svg",
        id: "soup",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "tarta",
        labelKey: "symbol.komunicare.food.pie",
        image: "/symbols/komunicare/tarta.svg",
        id: "pie",
        backgroundColor: "rgb(187, 222, 251)"
      }
    ],
    id: "foodBoard",
    locale: "en-US",
    format: "cboard",
    description: "",
    isFixed: true,
    nameKey: "symbol.komunicare.food",
    author: "Komunicare",
    email: "info@komuni.care",
    caption: "/symbols/komunicare/portada_comidas.svg",
    grid: {
      rows: 5,
      columns: 6,
      order: [
        ["fruitsAndVegetables", "breakfastsBoard", "rice", "meat", "noodles", "burger"],
        ["boiledEgg", "friedEgg", "milanesa", "bread", "hotdog", "pancake"],
        ["fries", "fish", "pizza", "chicken", "puree", "cheese"],
        ["sandwich", "soup", "pie", "ketchup", "mayonnaise", "golfSauce"],
        ["salt", null, null, null, null, null],
      ]
    }
  },
  {
    isPublic: false,
    tiles: [
      {
        label: "yogur",
        labelKey: "symbol.komunicare.food.breakfasts.yogurt",
        image: "/symbols/komunicare/yogur.svg",
        id: "yogurt",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "torta",
        labelKey: "symbol.komunicare.food.breakfasts.cake",
        image: "/symbols/komunicare/torta.svg",
        id: "cake",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "te",
        labelKey: "symbol.komunicare.food.breakfasts.te",
        image: "/symbols/komunicare/te.svg",
        id: "tea",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "submarino",
        labelKey: "symbol.komunicare.food.breakfasts.submarine",
        image: "/symbols/komunicare/submarino.svg",
        id: "submarine",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "mate",
        labelKey: "symbol.komunicare.food.breakfasts.mate",
        image: "/symbols/komunicare/mate.svg",
        id: "mate",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "mate cocido",
        labelKey: "symbol.komunicare.food.breakfasts.mateCocido",
        image: "/symbols/komunicare/mate_cocido.svg",
        id: "mateCocido",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "limonada",
        labelKey: "symbol.komunicare.food.breakfasts.lemonade",
        image: "/symbols/komunicare/limonada.svg",
        id: "lemonade",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "licuado",
        labelKey: "symbol.komunicare.food.breakfasts.smoothie",
        image: "/symbols/komunicare/licuado.svg",
        id: "smoothie",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "leche",
        labelKey: "symbol.komunicare.food.breakfasts.milk",
        image: "/symbols/komunicare/leche.svg",
        id: "milk",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "jugo de frutas",
        labelKey: "symbol.komunicare.food.breakfasts.fruitJuice",
        image: "/symbols/komunicare/jugo_de_frutas.svg",
        id: "fruitJuice",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "jugo de naranja",
        labelKey: "symbol.komunicare.food.breakfasts.orangeJuice",
        image: "/symbols/komunicare/jugo_de_naranja.svg",
        id: "orangeJuice",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "helado",
        labelKey: "symbol.komunicare.food.breakfasts.icecream",
        image: "/symbols/komunicare/helado.svg",
        id: "icecream",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "galletitas saladas",
        labelKey: "symbol.komunicare.food.breakfasts.crackers",
        image: "/symbols/komunicare/galletitas_saladas.svg",
        id: "crackers",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "galletitas dulces",
        labelKey: "symbol.komunicare.food.breakfasts.cookies",
        image: "/symbols/komunicare/galletitas_dulces.svg",
        id: "cookies",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "frutos secos",
        labelKey: "symbol.komunicare.food.breakfasts.driedFruits",
        image: "/symbols/komunicare/frutos_secos.svg",
        id: "driedFruits",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "facturas",
        labelKey: "symbol.komunicare.food.breakfasts.facturas",
        image: "/symbols/komunicare/facturas.svg",
        id: "facturas",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "chocolatada",
        labelKey: "symbol.komunicare.food.breakfasts.chocolateMilk",
        image: "/symbols/komunicare/chocolatada.svg",
        id: "chocolateMilk",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "cereales",
        labelKey: "symbol.komunicare.food.breakfasts.cereals",
        image: "/symbols/komunicare/cereales.svg",
        id: "cereals",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "café",
        labelKey: "symbol.komunicare.food.breakfasts.coffee",
        image: "/symbols/komunicare/cafe.svg",
        id: "coffee",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "bizcochuelo",
        labelKey: "symbol.komunicare.food.breakfasts.spongeCake",
        image: "/symbols/komunicare/bizcochuelo.svg",
        id: "spongeCake",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "agua",
        labelKey: "symbol.komunicare.food.breakfasts.water",
        image: "/symbols/komunicare/agua.svg",
        id: "water",
        backgroundColor: "rgb(187, 222, 251)"
      },
    ],
    id: "breakfastsBoard",
    locale: "es-ES",
    format: "cboard",
    description: "",
    isFixed: true,
    nameKey: "symbol.komunicare.food.breakfast",
    author: "Komunicare",
    email: "info@komuni.care",
    caption: "/symbols/komunicare/portada_desayunos_meriendas.svg",
    grid: {
      rows: 4,
      columns: 6,
      order: [
        ["water", "coffee", "beer", "chocolateMilk", "coke", "softDrinks"],
        ["fruitJuice", "orangeJuice", "milk", "smoothie", "soda", "submarine"],
        ["wine", "IWant", "dontLike", null, null, null],
        [null, null, null,null, null, null]
      ]
    }
  },
  {
    isPublic: false,
    tiles: [
      {
        label: "ananá",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.pineapple",
        image: "/symbols/komunicare/anana.svg",
        id: "pineapple",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "arándanos",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.blueberries",
        image: "/symbols/komunicare/arandanos.svg",
        id: "blueberries",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "bananas",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.bananas",
        image: "/symbols/komunicare/bananas.svg",
        id: "bananas",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "batata",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.sweetPotato",
        image: "/symbols/komunicare/batata.svg",
        id: "sweetPotato",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "berenjena",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.eggplant",
        image: "/symbols/komunicare/berenjena.svg",
        id: "eggplant",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "brócoli",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.broccoli",
        image: "/symbols/komunicare/brocoli.svg",
        id: "broccoli",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "cebolla",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.onion",
        image: "/symbols/komunicare/cebolla.svg",
        id: "onion",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "cebollita de verdeo",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.greenOnion",
        image: "/symbols/komunicare/cebollita_de_verdeo.svg",
        id: "greenOnion",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "cerezas",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.cherry",
        image: "/symbols/komunicare/cerezas.svg",
        id: "cherry",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "chauchas",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.greenBean",
        image: "/symbols/komunicare/chauchas.svg",
        id: "greenBean",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "choclo",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.corn",
        image: "/symbols/komunicare/choclo.svg",
        id: "corn",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "ciruela",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.plum",
        image: "/symbols/komunicare/ciruela.svg",
        id: "plum",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "durazno",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.peach",
        image: "/symbols/komunicare/durazno.svg",
        id: "peach",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "ensalada",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.salad",
        image: "/symbols/komunicare/ensalada.svg",
        id: "salad",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "espárragos",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.asparagus",
        image: "/symbols/komunicare/esparragos.svg",
        id: "asparagus",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "espinaca",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.spinach",
        image: "/symbols/komunicare/espinaca.svg",
        id: "spinach",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "frutillas",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.strawberries",
        image: "/symbols/komunicare/frutillas.svg",
        id: "strawberries",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "kiwi",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.kiwi",
        image: "/symbols/komunicare/kiwi.svg",
        id: "kiwi",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "lechuga",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.lettuce",
        image: "/symbols/komunicare/lechuga.svg",
        id: "lettuce",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "mandarina",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.tangerine",
        image: "/symbols/komunicare/mandarina.svg",
        id: "tangerine",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "manzana",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.apple",
        image: "/symbols/komunicare/manzana.svg",
        id: "apple",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "melón",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.melon",
        image: "/symbols/komunicare/melon.svg",
        id: "melon",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "morrón",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.redPepper",
        image: "/symbols/komunicare/morron.svg",
        id: "redPepper",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "naranja",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.orange",
        image: "/symbols/komunicare/naranja.svg",
        id: "orange",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "papa",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.potato",
        image: "/symbols/komunicare/papa.svg",
        id: "potato",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pepino",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.cucumber",
        image: "/symbols/komunicare/pepino.svg",
        id: "cucumber",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pera",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.pear",
        image: "/symbols/komunicare/pera.svg",
        id: "pear",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "pomelo",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.grapefruit",
        image: "/symbols/komunicare/pomelo.svg",
        id: "grapefruit",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "rabanito",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.radish",
        image: "/symbols/komunicare/rabanito.svg",
        id: "radish",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "repollo",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.cabbage",
        image: "/symbols/komunicare/repollo.svg",
        id: "cabbage",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "sandía",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.watermelon",
        image: "/symbols/komunicare/sandia.svg",
        id: "watermelon",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "tomate",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.tomato",
        image: "/symbols/komunicare/tomate.svg",
        id: "tomato",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "uvas",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.grapes",
        image: "/symbols/komunicare/uvas.svg",
        id: "grapes",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "zanahoria",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.carrot",
        image: "/symbols/komunicare/zanahoria.svg",
        id: "carrot",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "zapallo",
        labelKey: "symbol.komunicare.food.fruitsAndVegetables.pumpkin",
        image: "/symbols/komunicare/zapallo.svg",
        id: "pumpkin",
        backgroundColor: "rgb(187, 222, 251)"
      }     
    ],
    id: "fruitsAndVegetablesBoard",
    locale: "es-ES",
    format: "cboard",
    description: "",
    isFixed: true,
    nameKey: "symbol.komunicare.food.fruitsAndVegetables",
    author: "Komunicare",
    email: "info@komuni.care",
    caption: "/symbols/komunicare/portada_frutas_vegetales.svg",
    grid: {
      rows: 5,
      columns: 7,
      order: [
        ["pineapple", "blueberries", "bananas", "sweetPotato", "eggplant", "broccoli", "watermelon"],
        ["onion", "greenOnion", "cherry", "greenBean", "corn", "plum", "tomato"],
        ["peach", "salad", "asparagus", "spinach", "strawberries", "kiwi", "grapes"],
        ["lettuce", "tangerine", "apple", "melon", "redPepper", "orange", "carrot"],
        ["potato", "cucumber", "pear", "grapefruit", "radish", "cabbage", "pumpkin"]
      ]
    }
  },
  {
    isPublic: false,
    tiles: [
      {
        label: "abuela",
        labelKey: "symbol.komunicare.family.grandmother",
        image: "/symbols/komunicare/abuela.svg",
        id: "grandmother",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "abuelo",
        labelKey: "symbol.komunicare.family.grandfather",
        image: "/symbols/komunicare/abuelo.svg",
        id: "grandfather",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "bebé",
        labelKey: "symbol.komunicare.family.baby",
        image: "/symbols/komunicare/bebe.svg",
        id: "baby",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "hermana",
        labelKey: "symbol.komunicare.family.sister",
        image: "/symbols/komunicare/hermana.svg",
        id: "sister",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "hermano",
        labelKey: "symbol.komunicare.family.brother",
        image: "/symbols/komunicare/hermano.svg",
        id: "brother",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "hija",
        labelKey: "symbol.komunicare.family.daughter",
        image: "/symbols/komunicare/hija.svg",
        id: "daughter",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "hijo",
        labelKey: "symbol.komunicare.family.son",
        image: "/symbols/komunicare/hijo.svg",
        id: "son",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "madre",
        labelKey: "symbol.komunicare.family.mother",
        image: "/symbols/komunicare/madre.svg",
        id: "mother",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "padre",
        labelKey: "symbol.komunicare.family.father",
        image: "/symbols/komunicare/padre.svg",
        id: "father",
        backgroundColor: "rgb(187, 222, 251)"
      }
    ],
    id: "familyBoard",
    locale: "es-ES",
    format: "cboard",
    description: "",
    isFixed: true,
    nameKey: "symbol.komunicare.family",
    author: "Komunicare",
    email: "info@komuni.care",
    caption: "/symbols/komunicare/portada_familia.svg",
    grid: {
      rows: 4,
      columns: 6,
      order: [
        ["water", "coffee", "beer", "chocolateMilk", "coke", "softDrinks"],
        ["fruitJuice", "orangeJuice", "milk", "smoothie", "soda", "submarine"],
        ["wine", "IWant", "dontLike", null, null, null],
        [null, null, null,null, null, null]
      ]
    }
  },
  {
    isPublic: false,
    tiles: [
      {
        label: "agua",
        labelKey: "symbol.komunicare.food.drinks.water",
        image: "/symbols/komunicare/agua.svg",
        id: "water",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "cafe",
        labelKey: "symbol.komunicare.food.drinks.coffee",
        image: "/symbols/komunicare/cafe.svg",
        id: "coffee",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "cerveza",
        labelKey: "symbol.komunicare.food.drinks.beer",
        image: "/symbols/komunicare/cerveza.svg",
        id: "beer",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "chocolatada",
        labelKey: "symbol.komunicare.food.drinks.chocolateMilk",
        image: "/symbols/komunicare/chocolatada.svg",
        id: "chocolateMilk",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "coca-cola",
        labelKey: "symbol.komunicare.food.drinks.coke",
        image: "/symbols/komunicare/cocacola.svg",
        id: "coke",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "gaseosas",
        labelKey: "symbol.komunicare.food.drinks.softDrinks",
        image: "/symbols/komunicare/gaseosas.svg",
        id: "softDrinks",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "jugo de frutas",
        labelKey: "symbol.komunicare.food.drinks.fruitJuice",
        image: "/symbols/komunicare/jugo_de_frutas.svg",
        id: "fruitJuice",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "jugo de naranja",
        labelKey: "symbol.komunicare.food.drinks.orangeJuice",
        image: "/symbols/komunicare/jugo_de_naranja.svg",
        id: "orangeJuice",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "leche",
        labelKey: "symbol.komunicare.food.drinks.milk",
        image: "/symbols/komunicare/leche.svg",
        id: "milk",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "te",
        labelKey: "symbol.komunicare.food.drinks.tea",
        image: "/symbols/komunicare/te.svg",
        id: "te",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "licuado",
        labelKey: "symbol.komunicare.food.drinks.smoothie",
        image: "/symbols/komunicare/licuado.svg",
        id: "smoothie",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "limonada",
        labelKey: "symbol.komunicare.food.drinks.lemonade",
        image: "/symbols/komunicare/limonada.svg",
        id: "lemonade",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "soda",
        labelKey: "symbol.komunicare.food.drinks.soda",
        image: "/symbols/komunicare/soda.svg",
        id: "soda",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "submarino",
        labelKey: "symbol.komunicare.food.drinks.submarine",
        image: "/symbols/komunicare/submarino.svg",
        id: "submarine",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "vino",
        labelKey: "symbol.komunicare.food.drinks.wine",
        image: "/symbols/komunicare/vino.svg",
        id: "wine",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "yo quiero",
        labelKey: "symbol.komunicare.food.drinks.IWant",
        image: "/symbols/komunicare/yo_quiero.svg",
        id: "IWant",
        backgroundColor: "rgb(187, 222, 251)"
      },
      {
        label: "no me gusta",
        labelKey: "symbol.komunicare.food.drinks.dontLike",
        image: "/symbols/komunicare/no_me_gusta.svg",
        id: "dontLike",
        backgroundColor: "rgb(187, 222, 251)"
      }
    ],
    id: "drinksBoard",
    locale: "es-ES",
    format: "cboard",
    description: "",
    isFixed: true,
    nameKey: "symbol.komunicare.food.drinks",
    author: "Komunicare",
    email: "info@komuni.care",
    caption: "/symbols/komunicare/portada_bebidas.svg",
    grid: {
      rows: 4,
      columns: 6,
      order: [
        ["water", "coffee", "beer", "chocolateMilk", "coke", "softDrinks"],
        ["fruitJuice", "orangeJuice", "milk", "tea", "smoothie", "lemonade"],
        ["soda", "submarine", "vino", "IWant", "dontLike", null],
        [null, null, null,null, null, null]
      ]
    }
  }
];

export default komunicare;
